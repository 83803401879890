<template>
	<div class="box">
		
		<div class="searchbox">
      <el-form :inline="true">
				<el-form-item label="关键字">
          <el-input v-model="body" clearable placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="phone" clearable placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="refundflag" clearable placeholder="请选择审核状态">
            <el-option
              v-for="item in refundFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
		
		<!-- 展示列表 -->
		<div class="allusers">
			共找到 <span>{{totalRow?totalRow:0}}</span> 条数据
		</div>
		<reviewBillList :tableData="tableData" :loading="loading"></reviewBillList>

		<!-- 分页 -->
		<div class="pagebox">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="curPage"
				:page-sizes="[10, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="totalRow"
			></el-pagination>
		</div>
		

		<!-- 审核表单 -->
		<el-dialog title="退款审核" width="700px" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" :before-close="beforeClose" append-to-body>
			<el-form :model="form"  label-width="120px" style="width: 90%;">
				<el-form-item label="审核结果">
					<el-select v-model="form.refundflag" clearable placeholder="请选择审核结果">
						<el-option
              v-for="item in refundResultFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核描述">
					<el-input type="textarea" clearable v-model="form.checkdesc" placeholder="请选择审核描述"></el-input>
				</el-form-item>
				<el-form-item>
					<span style="margin-right: 10px;">{{ smsToPhone() }}</span>
					<el-button @click="getSms" :disabled="countdown !=0 ">
						<span v-if="countdown===0">获取短信验证码</span>
						<span v-else>{{ countdown }}s 后重新获取</span>
					</el-button>
				</el-form-item>
				<el-form-item label="短信验证码">
					<el-input v-model="form.smscode" clearable placeholder="请输入短信验证码"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交审核</el-button>
    			<el-button @click="cancleFn">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
import { waitRefundPage, refundDoing, sendSms } from '@/api/refund/index.js'
import reviewBillList from './list';
export default {
	components: {
		reviewBillList,
	},
	data() {
		return {
			pageSize: 10,
			curPage: 1,
			tableData: [],
			totalRow:0,
			body:'',
			phone: '',
			refundflag: '',
			loading:true,
			refundFlags: [
				{
					label: '待审核',
					value: '0'
				},
				{
					label: '审核通过',
					value: '1'
				},
				{
					label: '审核不通过',
					value: '2'
				}
			],
			refundResultFlags:[
				{
					label: '审核通过',
					value: '1'
				},
				{
					label: '审核不通过',
					value: '2'
				}
			],
			form: {
				outtradeno: '',
				refundflag: '',
				checkdesc: '',
				smscode: ''
			},
			dialogFormVisible: false,
			countdown: 0,
			smsTimer: null
		};
	},
	mounted() {
		this.getListData();
		this.smsToPhone()
	},
	methods: {
		search(){
			this.curPage = 1;
			this.getListData();
		},
		//获取列表数据
		getListData() {
			var that = this;
			waitRefundPage({
				userid: this.GLOBAL.adminId(),
				pageSize: that.pageSize,
				pageNum: that.curPage,
				body: that.body,
				phone: that.phone,
				refundflag: that.refundflag,
			})
			.then(res => {
				that.loading = false;
				if (res.result == 200) {
					that.tableData = res.detail.list;
					that.totalRow = res.detail.totalRow;
				} else {
					that.$message({
						message: res.description,
						type: 'warning'
					});
				}
			});
		},
		//获取详情数据
		openCheckDialog(row){
			this.dialogFormVisible = true;
			this.form.outtradeno = row.outtradeno;
			// console.log(this.form)
		},
		//切换每页展示条数
		handleSizeChange(val) {
			this.pageSize = val;
			this.getListData();
		},
		//切换页数
		handleCurrentChange(val) {
			this.curPage = val;
			this.getListData();
		},
		//提交审核
		onSubmit() {
			if(this.form.refundflag === ''){
				this.$message.warning('请选择审核结果');
				return false;
			}
			if(this.form.checkdesc === ''){
				this.$message.warning('请输入审核描述');
				return false;
			}

			if(this.form.smscode === ''){
				this.$message.warning('请输入短信验证码');
				return false;
			}

			this.$confirm('是否确认提交审核?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				refundDoing({
					userid: this.GLOBAL.adminId(),
					outtradeno: this.form.outtradeno,
					refundflag: this.form.refundflag,
					checkdesc: this.form.checkdesc,
					smscode: this.form.smscode,
				})
				.then(res => {
					if (res.result == 200) {
						this.$message.success('操作成功');
						if(this.smsTimer){
							this.countdown = 0
							clearInterval(this.smsTimer)
						}
						this.cancleFn();
						this.search();
					} else {
						this.$message({
							message: res.description,
							type: 'warning'
						});
					}
				});
			}).catch(() => {
				      
			});
			
			
		},
		//获取短信验证码
		getSms(){
			sendSms({
				userid: this.GLOBAL.adminId()
			})
			.then(res => {
				if (res.result == 200) {
					this.$message.success('发送成功！')
					this.countdown = 29
				} else {
					this.$message({
						message: res.description,
						type: 'warning'
					});
				}
			});

			this.smsTimer = setInterval(()=>{
				if(this.countdown === 0){
					clearInterval(this.smsTimer)
					return false;
				}
				this.countdown --;
			},1000)
			
		},
		//获取手机号
		smsToPhone(){
			let phone = localStorage.getItem('phone');
			return phone.slice(0, 3) + '****' + phone.slice(-4)
		},
		//取消退款审核
		cancleFn(){
			this.dialogFormVisible = false;
			this.form = {
				outtradeno: '',
				refundflag: '',
				checkdesc: '',
				smscode: ''
			}
			// console.log(this.form)
		},
		//点击关闭按钮重置表单内容
		beforeClose(done){
			this.cancleFn();
			/* if(this.smsTimer){
				this.countdown = 0
				clearInterval(this.smsTimer)
			} */
			done();
		}
	}
};
</script>

<style scoped>
	.box{
		padding:20px;
		background: #fff;
	}
	.pagebox{
		margin:20px 0;
		text-align: right;
	}
	.allusers{
		margin:10px 0;
		color: #409EFF;
	}
	.allusers span{
		font-weight: bold;
	}
</style>
