import request from '@/utils/request'

//-------------退款审核---------

//待退款审核列表
export function waitRefundPage(data) {
  return request({
    url: '/MA_ReundFee/FindRefundPage',
    method: 'post',
    params: data
  })
}


//退款-审核
export function refundDoing(data) {
  return request({
    url: '/MA_ReundFee/RefundDoing',
    method: 'post',
    params: data
  })
}

//发送验证码
export function sendSms(data) {
  return request({
    url: '/MA_ReundFee/SendSms',
    method: 'post',
    params: data
  })
}



