<template>
		<el-table :data="tableData" border v-loading="loading" style="width: 100%">
			<el-table-column prop="outtradeno" label="支付的流水号" width="130px" show-overflow-tooltip></el-table-column>
			<el-table-column prop="joinpaymessage" label="支付成功返回的流水号"  width="150px" show-overflow-tooltip></el-table-column>
			<el-table-column prop="body" label="退款项"  width="140px" show-overflow-tooltip></el-table-column>
			<el-table-column prop="nickname" label="退款人昵称" width="110px"></el-table-column>
			<el-table-column prop="phone" label="退款人手机号" width="110px"></el-table-column>
			<!-- <el-table-column prop="miniopenid" label="退款人miniopenid"></el-table-column> -->
			<el-table-column prop="createtime" label="退款申请时间"  width="160px"></el-table-column>
			<el-table-column label="退款金额（元）" width="110px">
				<template slot-scope="scope">
					<span>{{ scope.row.refundmoney ? scope.row.refundmoney/100 : 0 }}</span>
				</template>
			</el-table-column>
			<el-table-column label="审核状态" width="120px">
				<template slot-scope="scope">
					<span v-if="scope.row.refundflag ==='1'">审核通过</span>
					<span v-if="scope.row.refundflag ==='2'">审核不通过</span>
					<span v-if="scope.row.refundflag ==='0'">待审核</span>
				</template>
			</el-table-column>
			<el-table-column label="审核描述" width="140px" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-if="scope.row.refundflag ==='0'">/</span>
					<span v-else>{{scope.row.checkdesc}}</span>
				</template>
			</el-table-column>
			
			<el-table-column label="退款操作人" width="110px" prop="refundman"></el-table-column>
			<el-table-column prop="description" label="退款返回说明"  width="110px" show-overflow-tooltip></el-table-column>
			<el-table-column prop="refundtime" label="退款操作时间" width="160px" show-overflow-tooltip></el-table-column>
			<el-table-column label="退款的流水号" prop="refundtradeno" width="110px" show-overflow-tooltip></el-table-column>
			<el-table-column fixed="right" label="操作" width="120">
				<template slot-scope="scope">
					<el-button :disabled="scope.row.refundflag !== '0'" @click="checkClick(scope.row)" type="primary" size="small">审核</el-button>
				</template>
			</el-table-column>
		</el-table>
</template>

<script>
export default {
	props: ['tableData', 'loading'],
	data() {
		return {
			
		};
	},
	methods: {
		checkClick(row) {
			this.$parent.openCheckDialog(row);
		}
	}
};
</script>
